@import "../../assets/scss/magic";
.q-page-holder {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(30);
    width: 100%;
    max-width: rem(480);
    margin: auto;
    > li {
        display: flex;
        align-items: center;
        justify-content: center;
        > svg {
            width: rem(300);
            @include mobile {
                width: rem(260);
            }
        }
        &:last-child {
            padding: rem(50);
            border-radius: rem(36);
            background: c(base);
            box-shadow: 0 rem(10) rem(30) rgba(0, 49, 123, 0.1);
            flex-direction: column;
            @include mobile {
                padding: rem(30);
            }
        }
    }
    form {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(20);
        font-size: rem(16);
        > div {
            margin: 0 !important;
            > div {
                border-width: rem(1);
                border-radius: rem(5) !important;
                background: none !important;
                > input {
                    padding: 0 rem(14) !important;
                    height: rem(44) !important;
                    + div {
                        > div {
                            border-width: rem(1);
                            border-radius: rem(5) !important;
                            background: none !important;
                            height: rem(44) !important;
                            padding: 0 rem(14) !important;
                            border-color: #DFE1E6 !important;
                            &:focus-within {
                                border-color: #4C9AFF !important;
                            }
                            > div {
                                padding: 0 !important;
                            }
                            svg {
                                color: #545b65;
                            }
                        }
                    }
                }
            }
        }
        > button {
            border-radius: rem(40);
            height: rem(50);
            line-height: rem(50);
            &:last-child {
                margin: -rem(6) 0 0;
            }
        }
        * {
            transition: color 400ms, background 400ms, opacity 400ms, border-color 400ms, box-shadow 400ms !important;
        }
    }
    label {
        span {
            color: inherit !important;
        }
    }
    .sub-group {
        display: grid;
        grid-template-columns: 100%;
        padding: rem(24);
        border: rem(1) dashed c(hero);
        grid-row-gap: rem(24);
        border-radius: rem(16) !important;
        > button {
            border-radius: rem(40);
            height: rem(44);
            line-height: rem(44);
        }
    }
    .sub-children-holder {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: rem(12);
        grid-column-gap: rem(20);
        font-size: rem(14);
        position: relative;
        > * {
            &:not(:nth-last-child(2)):not(:last-child) {
                grid-column: span 2;
            }
        }
        > ul {
            display: flex;
            align-items: center;
            font: 700 rem(14) / rem(20) $f;
            text-transform: uppercase;
            letter-spacing: rem(1);
            color: c(hero);
            button {
                width: rem(20);
                height: rem(20);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: #f3f3f5;
                color: rgba(black, 0.5);
                border-radius: 100%;
                margin: 0 0 0 rem(12);
                @include hover {
                    background: darken(#f3f3f5, 2);
                    color: rgba(black, 0.8);
                }
            }
        }
        > div {
            margin: 0 !important;
            > div {
                border-width: rem(1);
                border-radius: rem(5) !important;
                background: none !important;
                > input {
                    padding: 0 rem(14) !important;
                    height: rem(38) !important;
                }
            }
        }
    }
    .sub-item-delete {
        position: absolute;
        bottom: 100%;
        left: 100%;
        width: rem(24);
        height: rem(24);
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        box-shadow: 0 rem(10) rem(30) rgba(0, 49, 123, 0.1);
        transform: translate3d(-50%, 50%, 0);
        cursor: pointer;
        color: c(hero);
        @include hover {
            box-shadow: 0 rem(10) rem(30) rgba(0, 49, 123, 0.2);
        }
    }
}
