@import 'magic';
.absolutely-splash, .fixed-splash {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.absolutely-splash {
    position: absolute;
}
.fixed-splash {
    position: fixed;
}
.relative {
    position: relative;
}
