@import 'magic';
:root {
    @each $name, $color in $c {
        --#{$name}: #{$color};
    }
}
* {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0 !important;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    &, &:before, &:after {
        box-sizing: border-box;
    }
}
a {
    color: inherit;
    text-decoration: none;
    transition: 400ms color, 400ms background;
}
button,
input,
optgroup,
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    font: inherit;
    appearance: none;
    display: block;
    color: inherit;
    caret-color: c(brand);
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
    &::-ms-clear {
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        background: none;
    }
    @include placeholder {
        opacity: 1;
        font-family: inherit;
    }
}
input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
}
html {
    font: 10px / 1.6 $f;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    max-width: 100vw;
    background: white;
    color: #000;
    user-select: none;
    @include mobile {
        font-size: 9.5px;
    }
}
body {
    font-size: rem(18);
    min-height: 100%;
    display: flex;
}
#root {
    width: 100%;
    margin: auto;
    display: flex;
    padding: rem(40) rem(8);
}
h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    line-height: normal;
}
li {
    display: block;
}
.ellipsis-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
.pointer-events-none {
    pointer-events: none;
}
.cursor-pointer {
    cursor: pointer;
}
.user-select {
    user-select: text;
    > * {
        user-select: text;
    }
}
