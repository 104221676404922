@import "../../assets/scss/magic";
.pdf-generator-holder {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
}
.print-page {
    width: 1224px;
    height: 1584px;
    display: flex;
    align-items: flex-start;
    background: no-repeat url("media/bg.jpeg") 50% 50% / cover;
    position: relative;
}
.print-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 142px 11.5196078431%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
    &:first-child {
        > * {
            filter: blur(0.3px);
        }
    }
    &:nth-child(2) {
        color: #444141;
        opacity: 0.5;
        > * {
            filter: blur(0.6px);
        }
    }
    &:last-child {
        color: transparent;
        .logo, .main-info, h3, .details {
            opacity: 0;
        }
    }
    .logo {
        img {
            width: 460px;
            height: 132px;
        }
    }
    h2 {
        text-transform: uppercase;
        font: 700 28px / 1 $f;
        margin: 40px 0;
    }
    .details {
        align-self: flex-start;
        text-align: left;
        display: grid;
        grid-template-columns: 100%;
        font: 700 18px / 1.2 $f;
        grid-row-gap: 18px;
        margin: 0 0 50px;
        p {
            &:first-child {
                font-size: 24px;
            }
            &:last-child {
                font-size: 20px;
                span {
                    border-bottom: 1px solid black;
                }
            }
        }
    }
    h3 {
        font: 700 28px / 1.2 $f;
        letter-spacing: 6px;
        margin: 0 0 30px;
        span {
            border-bottom: 1px solid black;
        }
    }
    .main-info {
        text-align: left;
        width: 100%;
        display: grid;
        grid-row-gap: 28px;
        grid-template-columns: 100%;
        p {
            &:first-child {
                text-indent: 40px;
            }
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid black;
        font: 16px / 1.6 $f;
        td {
            border: 1px solid black;
            padding: 12px 20px;
            text-align: center;
            &:first-child {
                width: 1%;
            }
            &:nth-child(2) {
                text-align: left;
            }
            &:nth-child(4), &:nth-child(5) {
                width: 1%;
            }
        }
        tr {
            &:first-child, &:last-child {
                font-weight: 700;
            }
        }
    }
    .mark-holder {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 140px 0 0;
        font: 16px / 1.2 $f;
        > li {
            display: flex;
            align-items: center;
            > div {
                &.line {
                    align-self: flex-end;
                    width: 150px;
                    border-bottom: 1px solid black;
                    position: relative;
                }
                + div {
                    margin: 0 0 0 10px;
                }
            }
        }
    }
    .footnote {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        margin: 28px 0 0;
    }
}
.mark {
    position: absolute;
    width: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -53%);
    opacity: 0.9;
}
.signature {
    position: absolute;
    left: 50%;
    bottom: -18px;
    transform: translate(-50%, 0);
    width: 120px;
    filter: blur(0.1px);
    opacity: 0.8;
}
